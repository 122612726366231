<template>
  <div class="card-body">
    <div v-if="excelReferencesList">
      <ag-grid-vue
        :columnDefs="columnDefs.value"
        :rowData="rowData"
        :rowHeight="70"
        :headerHeight="90"
        :enableCellTextSelection="true"
        :ensureDomOrder="true"
        :pagination="true"
        :paginationPageSize="countOnPage"
        :defaultColDef="defaultColDef"
        :suppressRowHoverHighlight="true"
        :suppressPaginationPanel="true"
        :suppressRowClickSelection="true"
        :suppressRowTransform="true"
        :postSortRows="postSortRows"
        style="width: 100%"
        domLayout="autoHeight"
        @grid-ready="onGridReady"
      ></ag-grid-vue>

      <table-pagination
        :gridApi="gridApi"
        :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
        :countOnPage="countOnPage"
      />
    </div>
    <div v-else class="table-preloader">
      <preloader />
    </div>
  </div>
</template>

<script>
import {computed, onBeforeUnmount, reactive, ref, watch} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import ExcelStatusTextRenderer from "@/components/Tables/CellRenderers/ExcelStatusTextRenderer.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "../../../components/OtherElements/TablePagination.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";

export default {
  name: "excel-references-table",
  components: {
    CustomSelect2,
    VPagination,
    AgGridVue,
    ExcelStatusTextRenderer,
    Preloader,
    TablePagination,
  },
  setup(props, context) {
    const store = useStore(),
      route = useRoute(),
      router = useRouter(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      isPreloader = ref(false);
    const excelReferencesList = computed(
        () => store.state.references.excelReferencesList?.staffs
      ),
      rowData = computed(() => {
        return Object.entries(excelReferencesList.value).map(([staff, data]) => {
          return {
            email: staff,
            KPI: data.kpi ?? 0,
            status: data.status === 'success' ? 'успешно' : 'сотрудник с таким email не найден',
          };
        });
      });
    const columnDefs = reactive({
      value: [
        {
          field: "email",
          headerName: "Email",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "KPI",
          headerName: "Средний KPI",
          wrapText: true,
          minWidth: 120,
        },
        {
          field: "status",
          headerName: "Статус",
          cellStyle: params => params.value === 'успешно' ? { color: 'black' } : { color: 'red' },
          wrapText: true,
          minWidth: 120,
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };
    const listenerDisabledBtnCalculate = ref(false)
    const postSortRows = params => {
      let rowNodes = params.nodes;
      let nextInsertPos = 0;
      for (let i = 0; i < rowNodes.length; i++) {
        const status = rowNodes[i].data.status;
        if (status !== 'успешно') {
          listenerDisabledBtnCalculate.value = true
          rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
          nextInsertPos++;
        }
      }
    };

    watch(gridApi, () => {
      context.emit("disBtnCalc", listenerDisabledBtnCalculate);
    });

    onBeforeUnmount(() => {
      store.commit("references/setExcelReferencesList", null);
    });

    return {
      route,
      countOnPage,
      isPreloader,
      excelReferencesList,
      postSortRows,
      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi
    };
  },
};
</script>

<style lang="scss" scoped>
.head-sort {
  .form-group {
    min-width: 50%;
  }
}
</style>
